.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  z-index: 1145;
}

@media (max-width: 600px){
  .modal-content{
    /* border: 2px solid yellow; */
    width: 80svw;
    min-width: 90svw;
  }
}

.modal-content {
  /* background: purple; */
  background: linear-gradient(to top right, #0F011A, #521B84);
  /* border: 2px solid red; */
  font-family: "Roboto",sans-serif;
  padding: 20px;
  border-radius: 15px;
  width: 100%;
  max-width: 30vw;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:20px;
  color:white;
  padding-bottom: 2rem;
}

.modal-content .close-btn{
  /* border: 2px solid red; */
  width: 95%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -30px;
}

.modal-content header{
    /* border: 2px solid white; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
}
.modal-content header > *{
  margin: 0 10px;
}
.modal-content .adx-amt{
    /* border: 2px solid green; */
    display: flex;
    width: 50% ;
    flex-direction: row;
    justify-content: space-evenly;
}

.modal-content .adx-amt > span{
    display: block;
    display: flex;
}

.modal-content .purchase-info{
    /* border: 2px solid white; */
    width:90%;
}

.modal-content .purchase-info > div{
  margin:2px 0;
}

.modal-content .purchase-info .title{
    font-size: 11px;
}

.modal-content .purchase-info .purchase-id-box{
    background-color: #B772D9;
    border-radius: 3px;
    padding:2px 4px;
    color: black;
}

.modal-content .imp-note{
  line-height: 15px;
  font-size: 14px;
}

button {
  margin-top: 10px;
}

/* .connect-wallet-popup{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  z-index: 1145;
} */